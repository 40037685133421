import React, { useState, useEffect, useRef, useCallback } from 'react'
import '../App.css';
import '../styles/global.css';
import '../styles/responsive.css';
import "../styles/giftcard.css"
import { BsCart4 } from "react-icons/bs";
import { FaChevronRight, FaTrash, FaUserCircle } from "react-icons/fa"
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import {
  Row,
  Dropdown,
} from "react-bootstrap";
import NavDropdown from 'react-bootstrap/NavDropdown';
import InnerBanner from '../components/InnerBanner'
import axios from "axios";
import Select from 'react-select'
import { FaSearch } from 'react-icons/fa'
import ProductItem from './../components/elements/ProductItem';
import CheckoutBar from '../components/CheckoutBar';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomer } from '../Redux/ProductItem/action';
import { Bars } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Button, Col } from 'react-bootstrap';
import PromotionalSliderArea from './PromotionalSliderArea';
import { getCms } from '../Redux/GetCms/actions';
import { getcart } from '../Redux/CartType/actions';
import Footer from '../components/Footer';
import WelcomeModal from '../components/elements/WelcomeModal';
import WelcomePromotionalModal from '../components/elements/WelcomePromotionalModal';
import { AiOutlineClose } from "react-icons/ai";
import { FaChevronLeft } from "react-icons/fa";

import Offcanvas from "react-bootstrap/Offcanvas";
import RedeemProgress from '../components/elements/RedeemProgress';
import LoginModal from '../components/LoginModal';
import { deleteCookie, getNewCustomerToken, setCookie } from '../config/cookie.config';

// import Button from "react-bootstrap/Button";

const GiftCards = () => {
  const nameDomain = (window.location.host.split('.'));
  const checkCms = nameDomain[0].split('-');
  const [couponData, setCouponData] = useState([]);
  const [promoCouponData, setPromoCouponData] = useState([]);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");
  const [zero, setZero] = useState(false);
  const [loading, setLoading] = useState(true);
  const [disableBtn, setDisableBtn] = useState(false);
  const [debounceloding, setDebounceLoading] = useState(true)
  const customer = JSON.parse(window.localStorage.getItem('customer'));
  const [fixedFooter, setFixedFooter] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [searchedPromotionalData, setSearchedPromotionalData] = useState("");
  const [modalOpenShow ,setModalOpenShow]=useState(false)
  const [category, setCategory] = useState("");
  const [selectedOption, setSelectedOption] = useState({ value: "Most Relevant", label: "Most Relevant" });
  const [modalShowDC, setModalShowDC] = useState(false);
  const [paymentOption, setPaymentOption] = useState("");
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  let token;
  if (customer) {
    token = customer.token;
  } else {
    token = null;
  }
  const [promototedBrand, setPromotedBrand] = useState(false);
  useEffect(() => {
    const nameDomains = window.location.host.split('.');

    if (
      nameDomains[0] === 'dev' ||
      nameDomains[0] === 'staging' ||
      nameDomains[0] === 'payouts' ||
      nameDomains[0] === 'uat' ||
      nameDomains[0] === 'localhost:3000'
    ) {
      setPromotedBrand(false);
      if (customer !== null) {
        setPromotedBrand(false);
      }
    } else {
      setPromotedBrand(false)
    }

    const currentURL = window.location.href;

    if (!currentURL.includes('localhost')) {
      // Define _tip array in the global scope
      var _tip = _tip || [];

      const script = document.createElement('script');
      script.type = 'text/javascript';

      // Define js variable outside the template string
      var js = document.createElement('script'); // Create the script element here

      script.innerHTML = `
        // test
        (function(d,s,id){
            var tjs = d.getElementsByTagName(s)[0];
            if(d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.async = true;
            js.src = d.location.protocol + '//app.truconversion.com/ti-js/33039/a1d99.js';
            tjs.parentNode.insertBefore(js, tjs);
        }(document, 'script', 'ti-js'));
    `;
      document.head.appendChild(script);

      // Define onExternalScriptLoad function
      function onExternalScriptLoad() {
        _tip.push(['_trackIdentity', 'dev']);
      }

      // Wait for the dynamically created script to load
      js.onload = function () {
        onExternalScriptLoad();
      };
    }

  }, [customer]);
  const [ws, setWs] = useState(null);

  useEffect(() => {
    const newWs = new WebSocket('wss://dev.backend.ncentiva.com/websocket');
    newWs.onopen = () => {
      console.log('WebSocket connection established');
    };
    setWs(newWs);
    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, []);

  const dispatch = useDispatch();
  const [btnColor, setBtnColor] = useState('');
  const [loaderImage, setLoaderImage] = useState('');
  const [btnText, setBtnText] = useState('');
  const [paginate, setpaginate] = useState(21);
  const [loadMore, setLoadMore] = useState(false);
  const rbalance_redux = useSelector((state) => state.customer_balance_Reducer.data);
  // console.log(rbalance_redux, "rbalance_redux")
  const [primaryColor, setPrimaryColor] = useState('');
  const [secondaryColor, setSecondaryColor] = useState('');
  const navigate = useNavigate();
  const [length, setLength] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [showPage, setShowPage] = useState(1);
  const [moreLoading, setMoreLoading] = useState(true);
  const [selected, setSelected] = useState("");
  const [catergoryOption, setCategoryOption] = useState(true);
  const categoryName = useSelector((state) => state.sendCategorie_reducer.category);
  const showCategory = useSelector(state => state.sendCategorie_reducer.showCategory);
  const globalSearch = useSelector(state => state.SearchReducer.search);
  const [image, setImage] = useState("");
  const [apiResponse, setApiResponse] = useState(false)
  const [imageError, setImageError] = useState(true);
  const cmsData = useSelector((state) => state.cmsReducer);
  const checkoutBarRef = useRef(null);
  const CustomerID = customer?.customer?._id
  const [isActive, setActive] = useState(false);
  const [promoClick, setPromoClick] = useState(false);
  const [searchData, setSearchData] = useState("show_class");
  const [modal_status, setModal_status] = useState();
  const [backdropOpacity, setBackdropOpacity] = useState(0);
  const [isBackdropVisible, setIsBackdropVisible] = useState(false);
  const togglePromo = () => {
    setPromoClick(!promoClick)
  }

  useEffect(() => {
    dispatch(getcart(token));
  }, [dispatch, token]);

  useEffect(() => {
    const updateWidth = () => {
      const contentWidth = window.innerWidth;
      if (contentWidth > 767) {
        setActive(true);
      } else {
        setActive(false);
      }
    };

    updateWidth();
    window.addEventListener('resize', updateWidth);

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  useEffect(() => {
    if (search.length > 0) {
      setPromoClick(true);
    } else {
      setPromoClick(false);
    }
  }, [search])

  useEffect(() => {
    if (categoryName?.length > 0) {
      setPromoClick(true);
    } else {
      setPromoClick(false);
    }
  }, [categoryName?.length])

  useEffect(() => {
    if (debounceloding === true) {
      setZero(false);
    }
  }, [debounceloding])


  var partner_id;
  if (customer) {
    var cust_id = customer.customer._id;
    partner_id = customer.customer.business_partner_id
  } else {
    //partner_id = "NCT-B-878611"
  }

  const options = [
    { value: "All Brands", label: "All Brands" },
    { value: "Most value", label: "Most value" },
    { value: "Most Relevant", label: "Most Relevant" },
    { value: "Order A-Z", label: "Order A-Z" },
    { value: "Order Z-A", label: "Order Z-A" }
  ]
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'var(--darkblue)' : 'var(--color)',
      fontSize: 14,
      padding: 10,
      marginBottom: 0,
      backgroundColor: state.isSelected ? 'rgba(215, 223, 233, 0.34);' : '#fff'
    }),

    control: () => ({
      width: 'auto',
      display: 'flex',
      border: '1px solid #ced4da',
      borderRadius: '.25rem',
      border: 'none', // Add this line to remove the border
      boxShadow: 'none', // Optional: Remove the box-shadow as well
      cursor: 'pointer',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const color = 'var(--color)';
      const fontSize = '14px';
      const transition = 'opacity 300ms';
      return { ...provided, opacity, transition, color, fontSize };
    }
  }

  let defaultCms_local=localStorage.getItem("defaultNcentivaCms")
  if(!customer && defaultCms_local){
    localStorage.removeItem("defaultNcentivaCms")
  }

  const handleeChange = (option) => {
    setSelectedOption(option);
    // if (option.label === "All Brands" || option.label === "Most value" || option.label === "Order A-Z" || option.label === "Order Z-A") {
    //   setSearch('');
    // }
    pagenumberSearch(1);
    setSort(option.label);
  }
  useEffect(() => {
    if (search) {
      setSelectedOption({ value: "Most Relevant", label: "Most Relevant" });
      setSort("Most Relevant");
    }
  }, [search]);
  const [getbrand, setGetBrand] = useState([]);
  const getbrands = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getallbrands`)
      .then((response) => {
        setGetBrand(response.data.brands);
      })
      .catch((error) => console.error(`Error: ${error}`));
  };

  const [hasNavigated, setHasNavigated] = useState(false);

  useEffect(() => {
    document.title = "ncentiva || Gift cards";

    const queryParams = new URLSearchParams(window.location.search);
    const serializedData = queryParams.get('data');

    if (serializedData && !hasNavigated) {
      try {
        const data = JSON.parse(decodeURIComponent(serializedData));
        const { token, ...dataWithoutToken } = data;

        // Split and store token parts
        const [header, payload, signature] = token.split('.');

        // Store header in localStorage
        const encodedHeader = btoa(header);
        
        window.localStorage.setItem('customer', JSON.stringify(data));
        window.localStorage.setItem("customer_CAACAO-012", encodedHeader);

        setCookie('customer_OAC-01', btoa(payload), 7); // Expires in 7 days
        
        setCookie('customer_AAC-02', btoa(signature), 7); // Expires in 7 days

        setHasNavigated(true);

        navigate('/');      
      } catch (error) {
        console.error('Error parsing or decoding data:', error);
      }
    }
    if (nameDomain[0] === "localhost:3000" || nameDomain[0] === "dev") {
      setHasNavigated(true);
    }
    setDebounceLoading(true);

    const getDataTimeout = setTimeout(() => {
      getCouponsData(pageNumber, search, category);
      getbrands();
      setDebounceLoading(false);
    }, 1500);

    return () => clearTimeout(getDataTimeout);
  }, [search, sort, pageNumber, category, hasNavigated, navigate]);


const a = getNewCustomerToken();
  useEffect(() => {
    getPromotedCouponsData()
  }, [])

  const getPromotedCouponsData = () => {
    let check_name = "cms_url"
    if (nameDomain[0] === "dev" || nameDomain[0] === "staging" || nameDomain[0] === "payouts" || nameDomain[0] === "uat" || nameDomain[0] === "localhost:3000") {

      nameDomain[0] = "";
    }
    if (checkCms[1] === "cms") {
      nameDomain[0] = checkCms[0];
      check_name = "partner_url"
    }
    if (customer) {
      token = customer.token;
    } else {
      token = null;
    }
    axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/promotedbrands?${check_name}=${nameDomain[0]}&partner_id=${partner_id}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((response) => {
        if (response.data.message === "Please Login") {
          localStorage.removeItem('customer');
          localStorage.removeItem('listItem');
          navigate('/');
          toast.warning("Session Expired Please Login Again")
        }
        const allcoupons = response.data.coupons;
        if (allcoupons.length < 1) {
        } else {
          setPromoCouponData(allcoupons);
        }
      })
      .catch(error => {
        console.error(`Error: ${error}`)
        console.log(error?.response?.data?.message);
        if (error.response.data.message === "Cms doesn't exist") {
          navigate('*')
        }
      });
  }
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  useEffect(() => {

    let check_name = "cms_url"
    if (nameDomain[0] === "dev" || nameDomain[0] === "staging" || nameDomain[0] === "payouts" || nameDomain[0] === "uat" || nameDomain[0] === "localhost:3000") {
      nameDomain[0] = "";
    }
    if (checkCms[1] === "cms") {
      nameDomain[0] = checkCms[0];
      check_name = "partner_url"
    }
    axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/get-customer-categories?${check_name}=${nameDomain[0]}&partner_id=${partner_id}`)
      .then((res) => {
        setCategories(res.data.categories);
        setSelectedCategories(res.data.selectedCategories);
      }).catch((err) => {
        console.log(err);
      })

    if (globalSearch?.length > 0 && categoryName.length === 0) {
      setSearch(globalSearch);
    }
  }, []);

  useEffect(() => {
    if (showCategory?.length > 0) {
      const unsortedCategories = categories;
      const sortedCategories = unsortedCategories.sort();
      const mergedArray = Array.from(new Set([...showCategory, ...sortedCategories]));
      setCategories(mergedArray);
    }
  }, [catergoryOption])
  const getCouponsData = (pageNumber, searchedPromotional, categoryData) => {
    setIsLoading(true)
    let check_name = "cms_url"
    if (nameDomain[0] === "dev" || nameDomain[0] === "staging" || nameDomain[0] === "payouts" || nameDomain[0] === "uat" || nameDomain[0] === "localhost:3000") {
      nameDomain[0] = "";
    }
    if (checkCms[1] === "cms") {
      nameDomain[0] = checkCms[0];
      check_name = "partner_url"
    }
    var search1
    let categoryName1
    if (search.length > 0) {
      search1 = search;
    } else if (selected.length > 0) {
      search1 = selected;
    } else {
      search1 = '';
    }
    if (customer) {
      token = customer.token;
    } else {
      token = null;
    }
    if (searchedPromotional) {
      search1 = searchedPromotional;

    }
    if (categoryData) {
      categoryName1 = categoryData;
      setCategory(categoryName1)
    } else {
      categoryName1 = "";

    }

    axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/allcouponsforcards?page=${pageNumber}&search=${search1}&sort=${sort}&${check_name}=${nameDomain[0]}&partner_id=${partner_id}&category=${categoryName1}`, {

      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((response) => {
        if (search || getbrand) {
          setMoreLoading(false)
        } else {
          setMoreLoading(true)
        }

        const allcoupons = response.data.coupons;
        setLength(allcoupons.length);
        setNumberOfPages(response.data.totalPages);
        setCouponData(allcoupons);
        setFilteredData(allcoupons);
        if (allcoupons.length === 0) {
          setZero(true);
          setCouponData(allcoupons);
        } else {
          setZero(false);
          setCouponData(allcoupons);
        }
        setIsLoading(false);
        setTimeout(() => {
          setMoreLoading(false);
        }, 1000)

      })
      .catch(error => console.error(`Error: ${error}`));
  }


  const handleCategorySelect = (category) => {
    setSearch('')
    setPageNumber(0)
    setShowPage(1)
    const encodedCategory = encodeURIComponent(category);
    setCategory(encodedCategory)
    getCouponsData(pageNumber, search, encodedCategory);

  };

  // console.log(rbalance_redux?.wallet_balance)

  const handleClickSearchSelect = (e) => {
    setPageNumber(0)
    setSearchedPromotionalData(e)
    setSearch(e)
    getCouponsData(pageNumber, e, categoryName);
  }
  useEffect(() => {
    if (cust_id) {
      dispatch(getCustomer(cust_id, token));
    }
  }, [cust_id, token, dispatch]);

  const defaultNcentiva = cmsData?.data?.cms?.default_ncentiva;
  useEffect(() => {
    const data = cmsData?.data?.cms;
    if (data?.default_ncentiva === false) {
      setBtnColor(data?.btn_color);
      setApiResponse(false);
      setLoaderImage(data?.brand_logo);
      setBtnText(data?.btn_text);
      setPrimaryColor(data?.primary_color);
      setSecondaryColor(data?.secondary_color);
      if (data?.brand_logo) {
        setImage(data?.brand_logo);
      }
      setLoading(false);
    } else if (data?.default_ncentiva === true) {
      setBtnColor('#FFAD31');
      setBtnText('white');
      setPrimaryColor("#2665F2");
      setSecondaryColor("#F5C400");
      setApiResponse(false);
      setLoaderImage("https://ncentiva-development.s3.eu-west-1.amazonaws.com/ncentiva_new_logo.png")
      setLoading(false);
    }
    if (data?.brand_logo) {
      setImageError(false);
    } else {
      setImageError(true);
    }

  }, [cmsData])


  useEffect(() => {
    const nameDomain1 = (window.location.host.split('.'));
    if (nameDomain1[0] !== "dev" || "staging" || "payouts" || "uat") {
      setLoading(true);
      setApiResponse(true);
      dispatch(getCms(nameDomain1[0]));
    }
  }, [dispatch])


  let items = [];
  let leftSide = pageNumber - 1;

  if (leftSide <= 0) leftSide = 0;
  let rightSide = pageNumber + 2;

  if (rightSide > numberOfPages) rightSide = numberOfPages;
  for (let number = leftSide; number < rightSide; number++) {

    items.push(
      <li key={number} className={(number === pageNumber ? "active" : "")}
        style={defaultNcentiva
          ? {
            backgroundColor: number === pageNumber ? `${primaryColor}` : "",
            color: number !== pageNumber ? "black" : "white",
            border: `1px solid transparent`
          }
          : {
            backgroundColor: number === pageNumber ? `${primaryColor}` : "",
            color: number !== pageNumber ? "black" : "white",
            border: `1px solid transparent`
          }
        }
        onClick={() => { setPageNumber(number); setShowPage(number + 1) }}>
        {number + 1}
      </li>,
    );
  }


  const pagenumberSearch = (e) => {
    if (e === "") {
      var pages = 1;
    } else {
      pages = parseInt(e);
    }
    var page = pages - 1;
    setTimeout(() => {

      if (pages < 1) {
        setPageNumber(0);
        setShowPage(1);

      } else if (pages > numberOfPages && filteredData.length !==0) {
        setPageNumber(numberOfPages - 1);
        setShowPage(numberOfPages);
      } else if (pages >= 1 && pages <= numberOfPages) {
        setPageNumber(page);
        setShowPage(pages);
      } else {
        setPageNumber(0);
        setShowPage(pages);
      }
    }, 1000)

  };

  const nextPage = () => {
    if (pageNumber < numberOfPages) {
      setPageNumber(pageNumber + 1);
      setShowPage(pageNumber + 2);
    }
  };

  const prevPage = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1);
      setShowPage(pageNumber);
    }
  };

  let ncentivaBal = parseFloat(0.00);
  const [isLoading, setIsLoading] = useState(true);
  const [customerWallet, setCustomerWallet] = useState(0);
  const [show, setShow] = useState(false);
  const [mobilelogo, setMobileLogo] = useState(false);
  const cartData = useSelector((state) => state.cartReducer);
  const reduxBalance = cartData?.data?.remaingBalance
  const reduxBonusCash = cartData?.data?.remaingWalletBalance
  const cusData = useSelector((state) => state.customer_balance_Reducer);
  let lists = cartData?.data?.cart
  let cusId = customer?.customer?._id

  if (lists) {
    lists = lists.filter((item) => item?.customer_id === cusId);
  } else {
    lists = ""
  }

  const cartList = lists;
  var name;
  var rbalance;
  let localWallet = window.localStorage.getItem('ncentiva_wallet');
  var id;
  let wallet = Number(0);
  let usedWalletBalance = Number(0);
  const cartDatalength = cartData?.data?.cart?.length

  for (let i = 0; i < cartList?.length; i++) {
    wallet += parseFloat(cartList[i]?.ncentiva_wallet)
    if (cartList[i]?.wallet === true) {
      usedWalletBalance += parseFloat(cartList[i].cost)
    }
  }

  useEffect(() => {
    if (cusData) {
      if (cusData?.data?.message === "Please Login") {
        localStorage.removeItem('customer');
        navigate('/login');
        toast.error("Session Expired Please Login Again")
      }
      let status = cusData?.data?.status;
      setModal_status(cusData?.data?.modal_status);
      if (status === false) {
        window.localStorage.removeItem('customer');
        navigate('/login');
        toast.warning("Your account has been deactivated");
      }
      if (cusData?.isError?.response?.status === 404) {
        localStorage.removeItem('customer');
        navigate('/login');
        toast.error("Your Account Doesn't Exist")
      } else if (cusData?.isError?.response?.status === 401) {
        localStorage.removeItem('customer');
        navigate('/login');
        toast.error("Session Expired Please Login Again")
      } else if (cusData?.isError?.response?.status === 400) {
        localStorage.removeItem('customer');
        navigate('/login');
        toast.error("Your Account Has Been Deactivated")
      }
    }
  }, [cusData])

  let r = parseInt(0);
  let bal;
  var contactList;
  let customerList = cartData?.data?.cart
  if (customerList) {
    customerList = customerList.filter((item) => item?.customer_id === cusId);
  }
  contactList = customerList;
  if (ncentivaBal >= 0.00) {
    for (let i = 0; i < contactList?.length; i++) {
      ncentivaBal += parseFloat(contactList[i]?.ncentiva_wallet);
      r += parseFloat(contactList[i]?.cost);
    }
  }
  else {
    for (let i = 0; i < cartList?.length; i++) {
      ncentivaBal += parseFloat(cartList[i]?.ncentiva_wallet);
      r += parseFloat(cartList[i]?.cost);
    }
  }
  bal = cusData?.data?.balance - r;

  if (bal >= 0.0) {
    ncentivaBal = ncentivaBal.toFixed(2);
  }

  if (customer) {
    id = customer?.customer?._id;

    name = customer?.customer?.first_name + " " + customer?.customer?.last_name;
  } else {
    name = null;
  }

  const gotoTransaction = () => {
    navigate("/customers/transaction")
  }

  const gotoMonetaryTransaction = () => {
    navigate(`/customers/monetarytransaction`)
  }

  const customerEmail = JSON.parse(window.localStorage.getItem('customer'));
  let email = customerEmail?.customer?.email;

  const handleResetPasswordConfirm = async () => {
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/send-reset-password-email`, {
        email,
        partner_id: customer?.customer?.business_partner_id,
      });
      if (data && data.message) {
        if (data.message === "Email doesn't exist") {
          toast.warning(data.message);
        } else if (data.message === "Email Field is Required") {
          toast.warning(data.message);
        } else {
          toast.success(data.message);
          localStorage.removeItem('customer_data')
          localStorage.removeItem('customer');
          navigate('/')
        }
      } else {
        console.error("Unexpected response format:", data);
      }
    } catch (err) {
      console.error("Error:", err);
      if (err.response && err.response.data) {
        toast.error(err.response.data);
      } else {
        toast.error("An error occurred while processing your request.");
      }
    }
    setShowResetPasswordModal(false);
  };

  const gotoResetPassword = () => {
    setShowResetPasswordModal(true);
  };



  const gotoProfile = () => {
    navigate("/customers/profile")
  }

  useEffect(() => {
    const data = cmsData.data?.cms;
    if (data?.default_ncentiva === false) {
      setBtnColor(data?.btn_color);
      setBtnText(data?.btn_text);
      if (data?.brand_logo) {
        setImage(data?.brand_logo)
        setMobileLogo(true)
      }
    } else if (data?.default_ncentiva === true) {
      setBtnColor('#FFAD31');
      setBtnText('white');
    }
    setIsLoading(false);
  }, [cmsData])

  const logout = async () => {
    await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/logout`, {
      headers: {
        'Authorization': `Bearer ${getNewCustomerToken()}`
      }
    }).then((res)=> {
      toast.success("You are Successfully Logged Out")
      setTimeout(() => {
        // Delete cookies
        deleteCookie('customer_OAC-01');
        deleteCookie('customer_AAC-02');

        // Remove token from localStorage
        window.localStorage.removeItem("customer_CAACAO-012");
        localStorage.removeItem('customer');
        localStorage.removeItem('customer_data');
        localStorage.removeItem('listItem');
        localStorage.removeItem('ncentiva_wallet');
        localStorage.removeItem('payoutItem');
          navigate('/login');
      }, 1000);
    }).catch((err)=> {
      console.log('Error :',err);
    })
  }

  const handleCartClick = () => {
    if (cartList?.length > 0) {
      // navigate('/checkout');
      // toggleShow()
      togggleClose()
    }
  };

  useEffect(() => {
        if (cartData?.data?.cart && cartData?.data?.cart?.length > 0) {
            toggleShow()
        } else {
            togggleClose()
        }
    }, [cartData?.data?.cart]);


  const LogoStyle = {
    width: "170px",
    objectFit: "scale-down",
  }
  let bals = Number(0);

  bals = (parseFloat(cusData?.data?.wallet_balance) + parseFloat(wallet));
  bals = parseFloat(bals) - parseFloat(usedWalletBalance)

  if (bals < "0.00") {
    bals = "0.00"
  }

  const handleClose = () => {
    setShow(false);
  }

  const handleShow = () => {
    setShow(true);
  }

  useEffect(() => {
    let timeoutId;
    function onUserActivity() {
      if (localStorage.getItem('customer')) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          localStorage.removeItem('customer');
          // Delete cookies
          deleteCookie('customer_OAC-01');
          deleteCookie('customer_AAC-02');

          // Remove token from localStorage
          window.localStorage.removeItem("customer_CAACAO-012");
          navigate('/login');
          toast.error("Session Expired. Please Login Again");
        }, 900000); // 15 minutes in milliseconds
      }
    }

    function resetTimeout() {
      onUserActivity();
    }

    // Events for desktop
    window.addEventListener('mousemove', resetTimeout);
    window.addEventListener('keydown', resetTimeout);

    // Events for mobile
    window.addEventListener('touchstart', resetTimeout);
    window.addEventListener('touchmove', resetTimeout);
    window.addEventListener('touchend', resetTimeout);

    // Initialize timeout on component mount
    onUserActivity();

    // Cleanup event listeners and timeout on component unmount
    return () => {
      // Cleanup
      window.removeEventListener('mousemove', resetTimeout);
      window.removeEventListener('keydown', resetTimeout);
      window.removeEventListener('touchstart', resetTimeout);
      window.removeEventListener('touchmove', resetTimeout);
      window.removeEventListener('touchend', resetTimeout);
      clearTimeout(timeoutId);
    };
  }, [navigate]);
  const toggleShow=()=>{
      setModalOpenShow(true)
  }
  const togggleClose=()=>{

    setModalOpenShow(!modalOpenShow)
  }
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
  const [headerHeight, setHeaderHeight] = useState("");
  const [canvasModalOpen, setCanvasModalOpen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setDeviceWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    const offcanvas= setTimeout(() => {
          const headerElement = document.querySelector(".gift_card_header");

          if (headerElement !== null) {
            const headerHeight = headerElement.clientHeight;
            setHeaderHeight(headerHeight);
            setCanvasModalOpen(true)
           }
    }, 3000);
    return () => {
      clearTimeout(offcanvas);
    };
  }, [deviceWidth]);

 const handleLinkClick = () => {
        navigate('/checkout');
    };
    // console.log(cartData, "cartData")
    const [disabled, setDisabled] = useState(false);
    const [usedFund, setUsedFund] = useState(0);
    const [bonus, setBonus] = useState(0);
    const [rFund, setrFund] = useState(0);
    const [totalValue, setTotalValue] = useState(0);
    var balance = 0;
    let customerBal = Number(customer?.customer?.balance);
    let listBalance = Number(0);
    let nBal = Number(0);
    let remaingWallet = Number(0);
    let walletUsed = Number(0);
    let walletBalance = Number(0);
function removeData(id, objId) {
        const localId = Number(id);
        setDisabled(true);
        setTimeout(() => {
            setDisabled(false);
        }, 3000);
        //get data from localstorage and store to contaclist array
        //we must to use JSON.parse, because data as string, we need convert to array
        var contactList;
        let carts = cartData?.data?.cart;
        if (carts) {
            carts = carts.filter((item) => item.customer_id === cusId);
        }
        const cartList = carts;
        contactList = cartList;
        let usedBalance = parseInt(0);
        for (let i = 0; i < contactList?.length; i++) {
            usedBalance = usedBalance + parseFloat(contactList[i].cost)
        }

        let deletedItem = contactList.filter(function (item) { return item.id === localId; });
        contactList = contactList.filter(function (value) {
            return value.id !== localId;
        });

        // let ncentivaBal = parseFloat(0.00);
        // let itemToRemove = contactList.filter(function (item) { return item.id !== id; });

        let localWallet = window.localStorage.getItem('ncentiva_wallet');
        let walletAmount = Number(0);
        let usedWallet = Number(0);
        for (let i = 0; i < cartList?.length; i++) {
            walletAmount = walletAmount + parseFloat(cartList[i].ncentiva_wallet)
            if (cartList[i].wallet) {
                usedWallet += parseFloat(cartList[i].cost);
            }
        }
        if (customerBal < usedBalance) {
            if (deletedItem[0]?.wallet === true) {
                nBal = parseFloat(usedWallet) - parseFloat(deletedItem[0]?.cost)
                window.localStorage.setItem('ncentiva_wallet', nBal)
                axios
                    .delete(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/deletecart/${objId}`)
                    .then(() => {
                        localStorage.setItem('listItem', JSON.stringify(contactList));
                        toast.success(`Deleted Successfully `);
                        if (ws) {
                            ws.send(JSON.stringify({ action: 'deleteItemToCart' }));
                        } else {
                            console.error('WebSocket connection is not established');
                        }
                        dispatch(getcart(token));
                        setTimeout(() => {
                            // window.location.reload();    
                        }, 2000);
                        return false;
                    })
                    .catch((err) => console.log(`Error ${err}`));
            } else {

                let walletBal = parseFloat(walletAmount) - parseFloat(deletedItem[0]?.ncentiva_wallet);
                walletBal.toFixed(2);
                const walletData = contactList.filter((cust) => cust.wallet === false);

                axios
                    .delete(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/deletecart/${objId}`)
                    .then(() => {
                        localStorage.setItem('listItem', JSON.stringify(walletData))
                        window.localStorage.setItem('ncentiva_wallet', walletBal)
                        if (ws) {
                            ws.send(JSON.stringify({ action: 'deleteItemToCart' }));
                        } else {
                            console.error('WebSocket connection is not established');
                        }
                        toast.success(`Deleted Successfully `);
                        dispatch(getcart(token));
                        setTimeout(() => {
                            // window.location.reload();
                        }, 2000);
                        return false;
                    })
                    .catch((err) => console.log(`Error ${err}`));
            }
        } else {
            for (let i = 0; i < contactList?.length; i++) {
                nBal += contactList[i].ncentiva_wallet
            }
            window.localStorage.setItem('ncentiva_wallet', nBal)

            axios
                .delete(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/deletecart/${objId}`)
                .then(() => {
                    localStorage.setItem('listItem', JSON.stringify(contactList))
                    if (ws) {
                        ws.send(JSON.stringify({ action: 'deleteItemToCart' }));
                    } else {
                        console.error('WebSocket connection is not established');
                    }
                    toast.success(`Deleted Successfully `);
                    dispatch(getcart(token));
                    setTimeout(() => {
                        // window.location.reload();
                    }, 2000);
                    return false;
                })
                .catch((err) => console.log(`Error ${err}`));
        }
    }


     for (let i = 0; i < cartList?.length; i++) {
        listBalance = listBalance + parseFloat(cartList[i].cost);
        walletBalance = walletBalance + parseFloat(cartList[i].ncentiva_wallet);
        if (cartList[i].wallet === true) {
            remaingWallet += parseFloat(cartList[i].cost);
            walletUsed += parseFloat(cartList[i].cost);
        }
    }
    let reaminingBonuscash = Number(0);
    reaminingBonuscash = parseFloat(rbalance_redux?.wallet_balance) - parseFloat(walletUsed);
    remaingWallet = parseFloat(walletBalance) - parseFloat(remaingWallet);

    remaingWallet = parseFloat(remaingWallet) + parseFloat(rbalance_redux?.wallet_balance)
       var name;
    var rbalance;
    var id;
    const getcustomer = (id) => {
        // axios
        //     .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getcustomer/${id}`)
        //     .then((response) => {


        if (cartList) {
            var sbalance = rbalance_redux?.balance;
            let sum = 0;
            let bonus = 0;
            let costValue = 0;
            for (var i = 0; i < cartList.length; i++) {
                if (cartList[i].wallet == false) {
                    sum += parseFloat(cartList[i].cost);
                    bonus += parseFloat(cartList[i].bonus);
                    costValue += parseFloat(cartList[i].costValue);
                }
            }

            rbalance = parseFloat(sbalance) - parseFloat(sum);
            setUsedFund(sum);
            setBonus(bonus);
            setrFund(rbalance);
            setTotalValue(costValue);
        } else {
            // setrFund(response.data.customer.balance);
            setrFund(rbalance_redux);
        }


        // })
        // .catch((error) => console.error(`Error: ${error}`));
    };
    if (customer) {
        id = customer?.customer?._id;

        name = customer?.customer?.first_name + " " + customer?.customer?.last_name;
    } else {
        name = null;
        balance = null;
    }



    useEffect(() => {
        if (cartData.data !== null) {
            // getcustomer(id, token);
            setTimeout(() => {
                getcustomer(id, token);
            }, 1000);
        }
    }, [cartData.data, usedFund, rFund, id]);

    useEffect(() => {
      window.fwSettings = {
        widget_id: 153000001586,
      };
  
      const loadScript = () => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.defer = true;
        script.src = 'https://widget.freshworks.com/widgets/153000001586.js';
        document.body.appendChild(script);
      };
      loadScript();
      return () => {
        document.body.removeChild(
          document.querySelector('script[src="https://widget.freshworks.com/widgets/153000001586.js"]')
        );
      };
    }, []);
  
    const hideWidget = () => {
      if (window.FreshworksWidget) {
        window.FreshworksWidget('hide', 'launcher');
      }
    };
    const showWidget = () => {
      if (window.FreshworksWidget) {
        window.FreshworksWidget('show', 'launcher');
      }
    };
    useEffect(()=>{
      setTimeout(() => {
        if(modalOpenShow && customer){
          hideWidget();
        }else{
          showWidget();
        }
      }, 1000);
    },[cartDatalength, modalOpenShow])
    const emptySearchContent =()=>{
      setSearch("")
  }
  const modalOnchange = (e) => {
    setPaymentOption(e.target.value);
  };
  localStorage.setItem("defaultNcentivaCms", defaultNcentiva);
  const handleConfirmation = (result) => {
  //uplifting the addCart and addFixedCart function from
  //productitem to here and pass it to another child of it
  };

  const closeCheckoutCart = useCallback(() => {
    setBackdropOpacity(0);
    togggleClose();
    setTimeout(() => {
      setIsBackdropVisible(false);
    }, 300); 
  }, [togggleClose]);
  useEffect(() => {
    const shouldShowBackdrop = cartDatalength !== 0 && customer && canvasModalOpen && modalOpenShow;
    
    if (shouldShowBackdrop) {
      setIsBackdropVisible(true);
      setTimeout(() => setBackdropOpacity(1), 300);
    } else {
      setBackdropOpacity(0);
      setTimeout(() => setIsBackdropVisible(false), 300); 
    }
  }, [cartDatalength, customer, canvasModalOpen, modalOpenShow]);

  const [showLoginModal, setShowLoginModal] = useState(false);

  useEffect(() => {
    // Check if the user is already logged in
    const isLoggedIn = localStorage.getItem('customer');
    if (!isLoggedIn) {
      setTimeout(()=>{
        setShowLoginModal(true);
      }, 2500)
    }
  }, []);
  return (
    <div className='zoomScreen'>
      {isBackdropVisible && (
        <div 
          className="offcanvas-backdrop show" 
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: `rgba(0, 0, 0, ${backdropOpacity})`,
            zIndex: 1050, 
            transition: 'background-color 0.35s ease-in-out'
          }}
          onClick={closeCheckoutCart}
        ></div>
      )}
      <>
        {loading || moreLoading || cmsData?.loading ? (
          <>
            {apiResponse ?
              <></> :
              <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: '100vh' }}>
                {imageError ? "" : (
                  <img
                    src={
                      loaderImage
                    }
                    alt=""
                    style={{ width: "150px" }}
                  />
                )}
                <Bars
                  height="60"
                  width="100%"
                  margin='auto'
                  // color={primaryColor}
                  color="var(--newMainBlue)"
                  ariaLabel="bars-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            }
          </>
        ) : (
          <>
          <div style={{ position: 'relative', zIndex: 1 }}>
            <div className='gift_card_header'>
              <div className="container-fluid">
                <div className={`header-content ${name === null ? "nologin_navbar" : "inlogin_navbar"}`}>
                  <div className="header-left d-flex " style={name === null ? { width: "auto" } : {}}>
                    <Link to="/" className={`${mobilelogo ? "mobile_View_logo" : "header-logo"}`}>
                      {cmsData.data?.cms?.default_ncentiva === false ? (
                        image && <img
                          src={image}
                          alt="Site Logo"
                          style={LogoStyle}
                        />
                      )
                        :
                        <img
                          src="https://ncentiva-development.s3.eu-west-1.amazonaws.com/ncentiva_new_logo.png"
                          alt="Site Logossss"
                          style={{ width: "170px"}}

                        />}
                    </Link>
                    {name == null ?
                      <ul className='login_show_mobile list-unstyled p-0 m-0 d-block d-sm-none'>
                        <li className="account-balance nav-item pt-2">
                          {btnColor ? <Link className="btn btn-main d-lg-inline" style={{ backgroundColor: btnColor, color: btnText, border: `1px solid ${btnColor}` }} to="/login">Login</Link> : ''}
                        </li>
                      </ul>
                      :
                      <>
                        <ul className='login_show_mobile list-unstyled p-0 m-0 d-flex d-xxl-none'>
                          <li className='balanace_seriel'>
                            <Dropdown>
                              <Dropdown.Toggle id="dropdown-basic" className='dropdown_header p-0'>
                              $ {Number(reduxBalance).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                  <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                </svg>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item >   <li>
                                  <li className="account-balance  nav-item">
                                    <span className="d-lg-inline" >Bonus Cash: </span> </li>
                                  <li className="account-balance  nav-item">
                                    <span className="d-lg-inline" >$ {Number(reduxBonusCash).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}   </span> </li>
                                </li></Dropdown.Item>
                                {/* <Dropdown.Item >  <li>
                                  <li className="account-balance  nav-item">
                                    <span className="d-lg-inline " >Balance</span> </li>
                                  <li className="account-balance nav-item">
                                    {cusData ? <span className="d-lg-inline" >$ {Number(reduxBalance).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span> : ""}  </li>
                                </li></Dropdown.Item> */}
                              </Dropdown.Menu>
                            </Dropdown>
                          </li>
                          <li className="account-balance bonus_cash nav-item">
                            <span className="d-lg-inline" >Bonus Cash: </span> </li>
                          <li className="account-balance bonus_cash nav-item">
                            <span className="d-lg-inline" >$ {Number(reduxBonusCash).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}   </span> </li>
                          <li className="account-balance bonus_cash nav-item">
                            <span className="d-lg-inline bonus_cash" >Balance</span> </li>
                          <li className="account-balance bonus_cash nav-item">
                            <span className="d-lg-inline" >$ {Number(reduxBalance).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span> </li>
                            {cartDatalength > 0 ? <li className="account-balance nav-item nav-cart  cart_hover" onClick={handleCartClick}>
                              {cartDatalength > 0 ? <span className="cart-count" style={{color:{primaryColor}}}>{cartDatalength}</span> : ''}
                              <BsCart4 className="fs-4" />
                            </li> :
                            <li className="account-balance nav-item" onClick={handleCartClick}>
                              {cartDatalength > 0 ? <span className="cart-count" style={{color:{primaryColor}}}>{cartDatalength}</span> : ''}
                              <BsCart4 className="fs-4" />
                            </li>}
                          <NavDropdown title={<span> <FaUserCircle className="fs-4" /> <span className='user-pname ms-1'>{cusData?.data?.first_name} {cusData?.data?.last_name}</span></span>} id="basic-nav-dropdown" >
                            <NavDropdown.Item onClick={() => gotoProfile()}>
                              My Profile
                            </NavDropdown.Item >
                            <NavDropdown.Item onClick={() => gotoTransaction()}>
                              My Giftcards
                            </NavDropdown.Item >
                            <NavDropdown.Item onClick={() => gotoMonetaryTransaction()} >
                              Monetary Transactions
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => gotoResetPassword()} >
                              Reset Password
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => handleShow()} >
                              Logout
                            </NavDropdown.Item>
                          </NavDropdown>
                          <Modal
                            show={show}
                            onHide={handleClose}
                            backdrop="static"
                            keyboard={false}
                            size="md"
                            centered
                          >
                            <Modal.Body>
                              <Col>
                                <img className="modal-img"
                                  src="/assets/images/exclamation.svg"
                                  alt=""
                                />
                                <br />
                                <br />
                                <h3 className="modal-text">Are you sure, you want to Log out?</h3>
                                <br />
                                <Row className="modal-row">
                                  <Col md={1}></Col>
                                  <Col md={4}>
                                    <Button className="btn btn-danger danger-btn" onClick={handleClose} >
                                      <span><b>Cancel</b></span>
                                    </Button>
                                  </Col>
                                  <Col className="middle-col" md={2}></Col>
                                  <Col md={4}>
                                    <Button className="btn btn-success success-btn " onClick={logout}>
                                      <span><b>Confirm</b></span>
                                    </Button>
                                  </Col>
                                  <Col md={1}></Col>
                                </Row>
                                <br />
                              </Col>
                            </Modal.Body>
                          </Modal>
                        </ul>
                      </>}
                  </div>
                  <div className="header_center w-100">
                    <section className={`product-filters pt-2 ${name === null ? "w-100" : ""} `} >
                      <div className="">
                        <div className="">
                          <div className="px-0 px-sm-2  col-xl-7 col-lg-7">      
                              <div className="search-content position-relative">
                                <div className="search-bar">
                                  <input
                                    type="text"
                                    className={`form-control ${name !== null ? "responsive_width" : ""}`}
                                    placeholder="Search for a brand, product or service"
                                    onChange={({ currentTarget: input }) => {
                                      pagenumberSearch(1)
                                      setSearch(input.value);
                                      setSelected('');
                                      setCategory("")
                                      setSort("Most Relevant")
                                      setPageNumber(0);
                                    }}
                                    value={search}
                                  />
                                  <FaSearch className="search-icon" />
                                </div>
                                  { search!=="" ? <AiOutlineClose onClick={emptySearchContent}  className='close-icon'  /> : ""}
                              </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                  <div className={`header-right ${name === null ? "nologin_navbar" : ""}`}>
                    {
                      name == null ?
                        <ul className="header-ul">
                          <li className="account-balance nav-item pt-2 login_show_desktop d-none d-sm-block">

                            {btnColor ? <Link className="btn btn-main d-lg-inline"
                               style={defaultNcentiva
                                ? { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid transparent` }
                                : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }}
                              to="/login">Login</Link> : ''}
                          </li>
                        </ul> :
                        <>
                          <ul className="header-ul d-none d-xxl-flex">
                            <li className='balanace_seriel'>
                              <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic" className='dropdown_header'>
                                  Balance                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item >   <li>
                                    <li className="account-balance  nav-item">
                                      <span className="d-lg-inline" >Bonus Cash</span> </li>
                                    <li className="account-balance  nav-item">
                                      <span className="d-lg-inline" >$ {Number(reduxBonusCash).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}   </span> </li>
                                  </li></Dropdown.Item>
                                  <Dropdown.Item >  <li>
                                    <li className="account-balance  nav-item">
                                      <span className="d-lg-inline " >Balance</span> </li>
                                    <li className="account-balance nav-item">
                                      {cusData ? <span className="d-lg-inline" >$ {Number(reduxBalance).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span> : ""}  </li>
                                  </li></Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </li>
                            <li className="account-balance bonus_cash nav-item">
                              <span className="d-lg-inline" >Bonus Cash:</span> </li>
                            <li className="account-balance bonus_cash nav-item p-2">
                              <span className="d-lg-inline" >$ {Number(reduxBonusCash).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}   </span> </li>
                            <li className="account-balance bonus_cash nav-item">
                              <span className="d-lg-inline bonus_cash" >Balance:</span> </li>
                            <li className="account-balance bonus_cash nav-item p-2">
                              <span className="d-lg-inline" >$ {Number(reduxBalance).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span> </li>
                            {cartDatalength > 0 ? <li className="account-balance nav-item nav-cart  cart_hover" onClick={handleCartClick}>
                              {cartDatalength > 0 ? <span className="cart-count">{cartDatalength}</span> : ''}
                              <BsCart4 className="fs-4" />
                            </li> :
                            <li className="account-balance nav-item" onClick={handleCartClick}>
                              {cartDatalength > 0 ? <span className="cart-count">{cartDatalength}</span> : ''}
                              <BsCart4 className="fs-4" />
                            </li>}
                            <NavDropdown title={<span> <FaUserCircle className="fs-4" /> <span className='user-pname ms-1'>{cusData?.data?.first_name} {cusData?.data?.last_name}</span></span>} id="basic-nav-dropdown" >
                              <NavDropdown.Item onClick={() => gotoProfile()}>
                                My Profile
                              </NavDropdown.Item >
                              <NavDropdown.Item onClick={() => gotoTransaction()}>
                                My Giftcards
                              </NavDropdown.Item >
                              <NavDropdown.Item onClick={() => gotoMonetaryTransaction()} >
                                Monetary Transactions
                              </NavDropdown.Item>
                              <NavDropdown.Item onClick={() => gotoResetPassword()} >
                                Reset Password
                              </NavDropdown.Item>
                              <NavDropdown.Item onClick={() => handleShow()} >
                                Logout
                              </NavDropdown.Item>
                            </NavDropdown>
                            <Modal
                              show={show}
                              onHide={handleClose}
                              backdrop="static"
                              keyboard={false}
                              size="md"
                              centered
                            >
                              <Modal.Body>
                                <Col>
                                  <img className="modal-img"
                                    src="/assets/images/exclamation.svg"
                                    alt=""
                                  />
                                  <br />
                                  <br />
                                  <h3 className="modal-text">Are you sure, you want to Log out?</h3>
                                  <br />
                                  <Row className="modal-row">
                                    <Col md={1}></Col>
                                    <Col md={4}>
                                      <Button className="btn btn-danger danger-btn" onClick={handleClose} >
                                        <span><b>Cancel</b></span>
                                      </Button>
                                    </Col>
                                    <Col className="middle-col" md={2}></Col>
                                    <Col md={4}>
                                      <Button className="btn btn-success success-btn" onClick={logout}>
                                        <span><b>Confirm</b></span>
                                      </Button>
                                    </Col>
                                    <Col md={1}></Col>
                                  </Row>
                                  <br />
                                </Col>
                              </Modal.Body>
                            </Modal>
                          </ul>
                        </>
                    }
                  </div>
                </div>
              </div>
              <InnerBanner
                bannerTitle="Explore"
                categories= {categories}
                selectedCategories= {selectedCategories}
                onCategorySelect={handleCategorySelect} 
                category={category}
                />
            </div>
            <span>{""}</span>
            <section className={`product-listing product_listing ${isActive ? "" : "toggle_margin"} ${name === null ? "nologin_navbar" : "inlogin_navbar"}`}  >
              {promototedBrand ? " " :
                <PromotionalSliderArea 
                 btnColor={btnColor} 
                  btnText={btnText} 
                  rbalance_redux={rbalance_redux?.balance}
                  wallet_balance={rbalance_redux?.wallet_balance}
                  disableBtn={disableBtn}
                  search={search}
                  promoClick={promoClick}
                  searchData={searchData}
                  promoCoupondata={promoCouponData}
                  setPromoClick={setPromoClick}
                  setDisableBtn={setDisableBtn}
                  ws={ws}
                  name={name}
                  setModalShowDC={setModalShowDC}
                  modalShowDC={modalShowDC}
                  handleClickSearchSelect={handleClickSearchSelect}
                  primaryColor={primaryColor}
                  secondaryColor={secondaryColor} 
                  setPaymentOption={setPaymentOption}
                  paymentOption={paymentOption}
                  modalOnchange={modalOnchange}
                  onConfirm={handleConfirmation}/>}
              <section className="product-listing centar_product_listing pb-3 position-relative">
                {promoCouponData?.length > 0 ?
                  <button className={`promotional_toggle text-dark  ${promoClick ? "rotate" : ""}`} onClick={() => togglePromo()} style={{ marginTop: "9px" }}>
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"></path></svg>
                  </button> :
                  ""}
                <div className="container-fluid">
                  <div className="row ">
                    <div className='col-md-3 col-sm-12 d-flex align-items-center ms-3 responsive_sort_font'> <span>Sort by: </span>
                      <div>
                        {search.length > 0 ?
                          <Select
                            styles={customStyles}
                            onChange={handleeChange}
                            options={options}
                            value={selectedOption}
                            defaultValue={{ value: "View: All", label: "View: All" }}
                            components={{
                              IndicatorSeparator: () => null
                            }}
                            isSearchable={false}
                            className='responsive_sort_font'
                          />
                          :
                           <Select
                            styles={customStyles}
                            onChange={handleeChange}
                            options={options}
                            value={selectedOption} // Controlled value for the dropdown
                            defaultValue={{ value: "Most Relevant", label: "Most Relevant" }}
                            components={{
                              IndicatorSeparator: () => null
                            }}
                            isSearchable={false}
                            className='responsive_sort_font'
                          />
                          }
                      </div>
                    </div>
                  </div>
                  <div className=' custom-container'  >
                    <div className="row customMarginGiftcards g-2 g-lg-3" style={{ marginBottom: numberOfPages === 0 ? "0px" : "20px", }}>
                      <div className="col-lg-12">
                      </div>
                      <>
                        {debounceloding || isLoading ? (
                          <div style={{ height: "800px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Bars
                              height="80"
                              width="100%"
                              margin='auto'
                              // color={primaryColor}
                              color="var(--newMainBlue)"
                              ariaLabel="bars-loading"
                              wrapperStyle={{}}
                              wrapperClass=""
                              visible={true}
                            />
                          </div>
                        ) : (
                          <>
                            {rbalance_redux &&
                              filteredData?.map((productitem, i) => (
                                <React.Fragment key={productitem._id}>
                                  <ProductItem
                                    data={productitem}
                                    btnColor={btnColor}
                                    btnText={btnText}
                                    rbalance_redux={rbalance_redux?.balance}
                                    wallet_balance={rbalance_redux?.wallet_balance}
                                    disableBtn={disableBtn}
                                    setDisableBtn={setDisableBtn}
                                    ws={ws}
                                    cmsData={cmsData}
                                    toggleShow={toggleShow}
                                    modalOpenShow={modalOpenShow}
                                    placement={"end"}
                                    togggleClose={togggleClose}
                                    primaryColor={primaryColor}
                                    secondaryColor={secondaryColor} 
                                    setPaymentOption={setPaymentOption}
                                    paymentOption={paymentOption}
                                    modalOnchange={modalOnchange}
                                    defaultNcentiva={defaultNcentiva}
                                    onConfirm={handleConfirmation}
                                  />
                                </React.Fragment>
                              ))}
                            {filteredData.length === 0 && !debounceloding && !isLoading && (
                              <h2 className="allbrands-head mb-4" style={{minHeight:"843px"}}> No Gift Card Found</h2>
                            )}
                          </>
                        )}
                      </>
                    </div>
                  </div>
                  <>
                    {zero || debounceloding ? "" :
                      <div className='text-center'>
                        {moreLoading ? "" : <Col className="mt-5 mb-4 pb-3">
                          {(filteredData.length >= 1 && pageNumber >= 1) || (filteredData.length >= 24) || ((filteredData.length >= 1) && (numberOfPages - 1 === pageNumber)) || (showPage === "") ?
                            <ul className="custPaginationCustomer  d-flex justify-content-center list-unstyled font14"
                              style={{ marginBottom: "100px", }}
                            >
                              {pageNumber === 0 ? (
                                ""
                              ) : (
                                <li onClick={prevPage}>Previous </li>
                              )}
                              {items}
                              {numberOfPages - 1 === pageNumber ? (
                                ""
                              ) : (
                                <li onClick={nextPage}>Next</li>
                              )}
                              <li className="font14"><input style={{ width: "25px", border: `1px solid ${primaryColor}`, borderRadius: '4px', textAlign: 'center' }} placeholder={showPage ? showPage : ""} type="number" name="number" id="" onChange={(e) => pagenumberSearch(e.target.value)}  ></input> <span style={{ color: `${primaryColor}` }}>/</span> {numberOfPages}</li>
                            </ul>
                            :
                            ""}
                        </Col>}
                        {/* {rbalance_redux && customer && cartDatalength > 0 ? <CheckoutBar 
                        cmsData={cmsData} btnColor={btnColor} btnText={btnText} primaryColor={primaryColor} secondaryColor={secondaryColor} rbalance_redux={rbalance_redux?.balance} wallet_balance={rbalance_redux?.wallet_balance} ws={ws} />
                          : ''} */}
                      </div>}
                  </>
                </div>
              </section>
            </section>
            {/* {cartDatalength > 0 ? null : <Footer  />} */}
            <Footer search={search} category={category} />
            {modal_status === true && name !== null
              ? <WelcomeModal cmsData={cmsData} isLoading={isLoading} setIsLoading={setIsLoading} />
              : modal_status === false && name !== null 
                ? <WelcomePromotionalModal cmsData={cmsData} isLoading={isLoading} setIsLoading={setIsLoading} />
                : " "
            }
            </div>
            <>

          
        <Offcanvas
          show={ cartDatalength!==0 && customer && canvasModalOpen ? true : false }
          placement={"end"} 
          backdrop={false}
          // className="checkout_offcanvas "
          className="checkout_offcanvas_content_onboard  checkout_offcanvas"
          style={{
            // bottom: '10',
            // top: "auto",
            // maxWidth: "450px",
            // height: "86.60%",
            // height: `calc(100vh - ${headerHeight}px)`,
            // border: "grey",
            marginTop: `calc(${headerHeight}px)`,
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            transition:cartDatalength===0 ?"all ease-in-out 1s": "all ease-in-out 1s",
            // position: "invert-layer",
            transform: !modalOpenShow ? "translateX(96%)" : "translateX(0%)",
            zIndex:"1300",
           
          }}
          scroll={true}
        >
          <button
                //  className={isActive ? "chekout-toggle text-dark" : "chekout-toggle text-dark rotate"}
                className="new-chekout-toggle text-dark position-absolute"
                onClick={togggleClose}
              >
                {modalOpenShow ?  <FaChevronRight /> : <FaChevronLeft />}
              </button>
          <Offcanvas.Header className='pb-0 pt-3'>
            <Offcanvas.Title>CheckOut Details</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body
            className="p-0 d-flex flex-column justify-content-between position-relative"
            style={{ height: "100%"}}
          >
             
            <div className="overflow-auto listBox" style={{ height: "75%", paddingTop: "10px"}}>

              {cartData?.data?.cart && btnColor ? (
                cartData?.data?.cart?.map((cust, index) => (
                  <div
                    className="  p-0 px-3 col-12 justify-content-between"
                    style={{
                      fontSize: "14px",
                      lineHeight: "16px",
                      fontWeight: "400",
                      padding: "10px",
                    }}
                    key={index}
                  >
                    <p
                      className="text-start pb-2"
                      style={{ fontSize: "16px", fontWeight: "600" }}
                    >
                      {cust.productname}
                    </p>
                    <div className="d-flex pb-1 flex-row justify-content-between" style={{marginTop: '6px'}}>
                      <div className="col-4">
                        <img
                          src={`${cust.img_path}`}
                          style={{ height: "75px", width: "120px" }}
                        />
                        {/* <div className="rounded-4 p-1 w-25 position-absolute text-center custom_discount">
                          +5.01%
                        </div> */}
                      </div>
                      <div className="col-5 ">
                        <div className="d-flex pb-1 col-12 flex-row justify-content-between">
                          <p className="col-8">Cost</p>
                          <p className="col-4 ">${cust.cost}</p>
                        </div>
                        {cust?.bonus > 0 ? <div className="d-flex pb-1 col-12 flex-row justify-content-between" style={{color: "#2665F2"}}>
                          <p className="col-8">Bonus</p>
                          <p className="col-4 ">${cust.bonus}</p>
                        </div> : ""}
                        <div className="d-flex pb-1 col-12 flex-row justify-content-between">
                          <p className="col-8">Cart value</p>
                          <p className="col-4 ">${cust.costValue}</p>
                        </div>
                        {cust.ncentiva_wallet > 0 ? <div className="d-flex pb-1 col-12 flex-row justify-content-between" style={{ color: 'rgb(245, 196, 0)' }}>
                          <p className="col-8">Bonus Cash</p>
                          <p className="col-4 ">
                            $
                            {Number(cust.ncentiva_wallet)
                              .toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </p>
                        </div> : ""}
                      </div>
                      <div className="d-flex   flex-row-reverse col-auto">
                        <FaTrash 
                        style={{ cursor: "pointer", color: "grey" }}
                        className="trash-icon"
                        onClick={() =>(
                          setTimeout(()=>{
                        removeData(cust.local_id, cust._id)
                          },500)
                        )}
                        />
                      </div>
                    </div>
                    <hr
                      style={{
                        width: "100%",
                        borderTop: "1px solid black",
                        margin: "10px 0",
                        height: "1px",
                      }}
                    />
                  </div>
                ))
              ) : (
                <p className="text-dark"> Cart Empty</p>
              )}
            </div>
            <div style={{ height: "auto" }} className="px-4 pt-2">
              <div className="checkout-middle">
                        {listBalance < customerBal ?
                            <RedeemProgress btnColor={btnColor} btnText={btnText} primaryColor={"#FF7020"} secondaryColor={secondaryColor} walletColor={"#f5c400"} lwidth="80%" rwidth="20.00%" amount={usedFund} rFund={rFund} bonus={bonus} wallet={walletUsed.toFixed(2)} />
                            :
                            <RedeemProgress btnColor={btnColor} btnText={btnText} primaryColor={"#FF7020"} secondaryColor={secondaryColor} walletColor={"#f5c400"} lwidth="80%" rwidth="20.00%" amount={usedFund} rFund="0.00" bonus={bonus} wallet={walletUsed.toFixed(2)} />
                        }
              </div>
              { primaryColor ? <div style={{ fontSize: "18px", fontWeight: "500"}}>
                <div
                  className="d-flex col-12 flex-row justify-content-between checkut_Funds_used"
                  // style={{ color: primaryColor}}
                  style={{ color: "#FF7020"}}
                >
                  <p className="col-9">Funds Used</p>
                  <p className="col-3 ">${usedFund === 0 ? 0 : Number(usedFund).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
                </div>
                <div className="d-flex col-12 flex-row justify-content-between checkout-Remaining">
                  <p className="col-9">Remaining </p>
                  <p className="col-3 ">${Number(rFund).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
                </div>
                <div className="d-flex col-12 flex-row justify-content-between checkout_bonus" style={{color: "#2665F2"}}>
                  <p className="col-9">Bonus</p>
                  <p className="col-3 ">${Number(bonus).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
                </div>
                {/* <div className="d-flex col-12 flex-row justify-content-between"> */}
                  { remaingWallet > 0 ? (
                      listBalance >= customerBal ? 
                  <div className="d-flex col-12 flex-row justify-content-between text-yellow checkout_bonus_cash" style={{ color: 'rgb(245, 196, 0)' }}>
                  <p className="col-9">Remaing Bonus Cash</p>
                  <p className="col-3 ">${Number(reaminingBonuscash).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
                  </div>
                  :
                  <div className="d-flex col-12 flex-row justify-content-between" style={{ color: 'rgb(245, 196, 0)' }}>
                  <p className="col-9">Remaing Bonus Cash</p>
                  <p className="col-3 ">${Number(reaminingBonuscash).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
                  </div>) : null}
                {/* </div> */}
              </div>: ""}
              <div>
                
             {cartList ? 
             <>
              { cartList.length > 0 && btnColor ?
                  <button to="/checkout"
                  onClick={handleLinkClick}
                    className="btn btn-main w-100 mt-3 hover_checkout_bar"
                    // style={{ backgroundColor: "#4221e5" }}
                     style={defaultNcentiva
                    ? { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid transparent` }
                    : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }}
                  >
                    Go To Checkout
                  </button>
                :
                  <>
                  { btnColor ? <Link to="/checkout"
                    className="btn btn-main w-100 mt-3"
                    // style={{ backgroundColor: "#4221e5" }}
                    style={defaultNcentiva
                    ? { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid transparent`, pointerEvents: 'none' }
                     : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}`, pointerEvents: 'none' }}
                  >
                    Go To Checkout
                  </Link>: ""}
                  </>
                  }
              </>
                :
                <>
                    {btnColor ?  <Link to="/checkout"
                      className="btn btn-main w-100 mt-3"
                      // style={{ backgroundColor: "#4221e5" }}
                      style={defaultNcentiva
                       ? { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid transparent`, pointerEvents: 'none' }
                       : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}`, pointerEvents: 'none' }}
                    >
                      Go To Checkout
                </Link> : ''}
                </>}
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>

        <LoginModal show={showLoginModal} onHide={() => setShowLoginModal(false)} />
        <Modal
        show={showResetPasswordModal}
        onHide={() => setShowResetPasswordModal(false)}
        backdrop="static"
        keyboard={false}
        size="md"
        centered
      >
        <Modal.Body>
          <Col>
            <img className="modal-img"
              src="/assets/images/exclamation.svg"
              alt=""
            />
            <br />
            <br />
            <h3 className="modal-text">Are you sure you want to proceed? When you reset the password, a link will be sent to your email to continue and this session will be closed.</h3>
            <br />
            <Row className="modal-row">
              <Col md={1}></Col>
              <Col md={4}>
                <Button className="btn btn-success success-btn" onClick={handleResetPasswordConfirm}>
                  <span><b>Confirm</b></span>
                </Button>
              </Col>
              <Col className="middle-col" md={2}></Col>
              <Col md={4}>
                <Button className="btn btn-danger danger-btn" onClick={() => setShowResetPasswordModal(false)} >
                  <span><b>Cancel</b></span>
                </Button>
              </Col>
              <Col md={1}></Col>
            </Row>
            <br />
          </Col>
        </Modal.Body>
      </Modal>
      </>
          </>

        )}
        <div id="freshworks-chat-widget-container"></div>
      </>
    </div>
  )
}

export default GiftCards